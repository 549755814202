import firebase from "firebase/app";
import "firebase/auth";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { authAtom } from "../atoms/auth.atoms";

export interface ISchool {
  id: string
  title: string
  color: string
  programs: string[]
  short: string
}

export interface IFirebaseAdminLoginReturn {
  loggedIn: boolean,
  user?: firebase.auth.UserCredential
} 

export function useFirebase(asStudent: boolean)  {

  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<ISchool[]>([])

  const [auth, setAuth] = useRecoilState(authAtom);

  useEffect(() => {
    asStudent && init();
  }, [])

  async function init()  {
    try {

      const snapshot = await firebase.firestore().collection('schools').get();

      const retrievedData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setData(retrievedData as ISchool[]);
      
    } catch {
      enqueueSnackbar('Prišlo je do napake...', { variant: 'error' });
    }
  }

  async function AdminLogin(email: string, password: string) {
    try {

      let user;
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
      
        // New sign-in will be persisted with session persistence.
        let res = firebase.auth().signInWithEmailAndPassword(email, password);
        return res;
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
      });

      // let user = await firebase.auth().signInWithEmailAndPassword(email, password);
      return true;
    } catch(e) {
      return false;    
    }
  }

  return {
    data,
    AdminLogin
  };
}
