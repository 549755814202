import React, { ReactElement } from 'react'
import styled from 'styled-components';

type LayoutProps = {
  bAuth?: boolean
}

export const Layout = styled.div<LayoutProps>`
  max-width: ${props => (props.bAuth ?? false) ? '1500px' : '1100px'};
  margin: 0 auto;
  padding: 0 24px;
`;