import { FormInputProps, IMappedFormData } from "../components/Forms/FormInput";
import firebase from "firebase/app";


export interface IFields {
  [key: string]: {
    [key: string]: Omit<FormInputProps, 'form'>;
  };
}

export const genderData: IMappedFormData[] = [
  { label: "Moški", value: 'M' },
  { label: "Ženska", value: 'Ž' },
];

export const booleanData: IMappedFormData[]  = [
  { label: "Da", value: 1 },
  { label: "Ne", value: 0 },
];

export const odlocbaData: IMappedFormData[]  = [
  { label: "Da", value: 1 },
  { label: "Ne", value: 0 },
  { label: "Ne še, v postopku", value: 2 },
];

export const shirtSizeData: IMappedFormData[]  = [
  { label: "S", value: 'S' },
  { label: "M", value: 'M' },
  { label: "L", value: 'L' },
  { label: "XL", value: 'XL' },
  { label: "XXL", value: 'XXL' },
];

export const premadeCommuteMethods = [
  'z avtobusom',
  'z vlakom',
  's starši'
]

export const premadeCurrentlyLivingWhere = [
  'doma',
  'v dijaškem domu',
  'pri zasebnikih',
  'pri sorodnikih v kraju šolanja',
  'v stanovanjski skupini'
]

export const booleanLookup = { 
  true: 'Da',
  false: 'Ne'
}

export const odlocbaLookup = { 
  1: 'Da',
  0: 'Ne',
  2: 'Ne še'
}

// Set the default date to Semptember 1. of this year
export const defaultMalicaDate =  `${new Date().getFullYear()}-09-01` ?? new Date().setMonth(8, 1);

export interface ISignUp {
  id: string;

  school: string;
  program: number;

  first_name: string;
  last_name: string;
  gender: string;
  date_of_birth: firebase.firestore.Timestamp;
  city_of_birth: string;
  country_of_birth: string;
  emso: string;
  tax_number: string;
  phone: string;
  email: string;

  permanent_street: string;
  permanent_house_number: string;
  permanent_post_number: string;
  permanent_city: string;

  temporary_street?: string;
  temporary_house_number?: string;
  temporary_post_number?: string;
  temporary_city?: string;

  parent1_first_name: string;
  parent1_last_name: string;
  parent1_street: string;
  parent1_post_number: string;
  parent1_city: string;
  parent1_tax_number: string;
  parent1_phone: string;
  parent1_email: string;
  parent1_house_number: string;

  parent2_first_name?: string;
  parent2_last_name?: string;
  parent2_street?: string;
  parent2_post_number?: string;
  parent2_city?: string;
  parent2_tax_number?: string;
  parent2_phone?: string;
  parent2_email?: string;
  parent2_house_number?: string;


  identificationPhoto?: string;
  lastYearCertificatePhoto?: string;
  examCertificatePhoto?: string;

  malica_is_signing_up: boolean;
  malica_from: firebase.firestore.Timestamp;

  will_rent_books: boolean;
  
  recognized_as_extraordinary: boolean;
  is_intersted_in_choir: boolean;
  currently_lives_where: string;

  is_currently_commuter: boolean;

  has_odlocba: boolean;

  createDate: firebase.firestore.Timestamp
}

export const fields: IFields = {
  school: {
    school: {
      field: "school",
      label: "Šola",
      required: true,
    },
    program: {
      field: "program",
      label: "Program",
      required: true,
    },
  },

  // This is a little phantom entry that only appears after the signUp has been processed
  createDate: {
    createDate: {
      field: "createDate",
      label: "Čas prijave",
      required: false,
      type: 'date',
      datePrecise: true
    },
  },

  student: {
    first_name: {
      field: "first_name",
      label: "Ime",
      required: true,
    },
    last_name: {
      field: "last_name",
      label: "Priimek",
      required: true,
    },
    gender: {
      field: "gender",
      label: "Spol",
      required: true,
      data: genderData,
      type: 'select'
    },
    date_of_birth: {
      field: "date_of_birth",
      label: "Datum rojstva",
      required: true,
      type: 'date'
    },
    city_of_birth: {
      field: "city_of_birth",
      label: "Kraj rojstva",
      required: true,
    },
    country_of_birth: {
      field: "country_of_birth",
      label: "Država rojstva",
      required: true,
    },
    emso: {
      field: "emso",
      label: "EMŠO",
      required: true,
    },
    tax_number: {
      field: "tax_number",
      label: "Davčna številka",
      required: true,
    },
    phone: {
      field: "phone",
      label: "Mobilna številka (dijaka/dijakinje)",
      required: false,
    },
    email: {
      field: "email",
      label: "Elektronski naslov (dijaka/dijakinje)",
      required: false,
    },
  },

  residence: {
    permanent_street: {
      field: "permanent_street",
      label: "Ulica",
      required: true,
    },
    permanent_house_number: {
      field: "permanent_house_number",
      label: "Hišna številka",
      required: false,
    },
    permanent_post_number: {
      field: "permanent_post_number",
      label: "Poštna številka",
      required: true,
    },
    permanent_city: {
      field: "permanent_city",
      label: "Kraj",
      required: true,
    },

    temporary_street: {
      field: "temporary_street",
      label: "Ulica",
      labelPrefix : 'Začasna',
      required: false,
    },
    temporary_house_number: {
      field: "temporary_house_number",
      label: "Hišna številka",
      labelPrefix : 'Začasna',
      required: false,
    },
    temporary_post_number: {
      field: "temporary_post_number",
      label: "Poštna številka",
      labelPrefix : 'Začasna',
      required: false,
    },
    temporary_city: {
      field: "temporary_city",
      label: "Kraj",
      labelPrefix : 'Začasni',
      required: false,
    },
  },

  parents: {
    parent1_first_name: {
      field: "parent1_first_name",
      label: "Ime",
      labelPrefix : 'Skrbnik #1',
      required: true,
    },
    parent1_last_name: {
      field: "parent1_last_name",
      label: "Priimek",
      labelPrefix : 'Skrbnik #1',
      required: true,
    },
    parent1_street: {
      field: "parent1_street",
      label: "Ulica",
      labelPrefix : 'Skrbnik #1',
      required: true,
    },
    parent1_house_number: {
      field: "parent1_house_number",
      labelPrefix : 'Skrbnik #1',
      label: "Hišna številka",
      required: false,
    },
    parent1_post_number: {
      field: "parent1_post_number",
      label: "Poštna številka",
      labelPrefix : 'Skrbnik #1',
      required: true,
    },
    parent1_city: {
      field: "parent1_city",
      label: "Kraj",
      labelPrefix : 'Skrbnik #1',
      required: true,
    },
    parent1_tax_number: {
      field: "parent1_tax_number",
      label: "Davčna številka",
      labelPrefix : 'Skrbnik #1',
      required: true,
    },
    parent1_phone: {
      field: "parent1_phone",
      label: "Telefonska številka",
      labelPrefix : 'Skrbnik #1',
      required: false,
    },
    parent1_email: {
      field: "parent1_email",
      label: "Elektronski naslov",
      labelPrefix : 'Skrbnik #1',
      required: false,
    },
    

    parent2_first_name: {
      field: "parent2_first_name",
      labelPrefix : 'Skrbnik #2',
      label: "Ime",
      required: false,
    },
    parent2_last_name: {
      field: "parent2_last_name",
      label: "Priimek",
      labelPrefix : 'Skrbnik #2',
      required: false,
    },
    parent2_street: {
      field: "parent2_street",
      label: "Ulica",
      labelPrefix : 'Skrbnik #2',
      required: false,
    },
    parent2_house_number: {
      field: "parent2_house_number",
      label: "Hišna številka",
      labelPrefix : 'Skrbnik #2',
      required: false,
    },
    parent2_post_number: {
      field: "parent2_post_number",
      label: "Poštna številka",
      labelPrefix : 'Skrbnik #2',
      required: false,
    },
    parent2_city: {
      field: "parent2_city",
      label: "Kraj",
      labelPrefix : 'Skrbnik #2',
      required: false,
    },
    parent2_tax_number: {
      field: "parent2_tax_number",
      label: "Davčna številka",
      labelPrefix : 'Skrbnik #2',
      required: false,
    },
    parent2_phone: {
      field: "parent2_phone",
      label: "Telefonska številka",
      labelPrefix : 'Skrbnik #2',
      required: false,
    },
    parent2_email: {
      field: "parent2_email",
      label: "Elektronski naslov",
      labelPrefix : 'Skrbnik #2',
      required: false,
    },
  },

  identification: {
    identificationPhoto: {
      field: "identificationPhoto",
      label: "Fotografija za šolsko izkaznico",
      required: true,
      type: 'file'
    }
  },
  
  shirtSize: {
    shirtSize: {
      field: "shirtSize",
      // label: "Vnesite konfekcijsko številko velikost majice (neobvezno).",
      label: '',
      alternativeLabel: "Številka majice",
      required: false,
      type: 'radio',
      data: shirtSizeData
    }
  },

  certificates: {
    lastYearCertificatePhoto: {
      field: "lastYearCertificatePhoto",
      label: "Spričevalo zaključni razred oz. letnik ",
      required: true,
      type: 'file'
    },
    examCertificatePhoto: {
      field: "examCertificatePhoto",
      label: "Spričevalo zaključni izpit",
      required: false,
      type: 'file'
    }
  },

  malica: {
    malica_is_signing_up: {
      field: "malica_is_signing_up",
      label: "Dijaka/dijakinjo prijavljam na šolsko malico",
      required: true,
      data: booleanData,
      lookup: booleanLookup,
      type: 'radio'
    },

    malica_from: {
      field: "malica_from",
      label: "Dijaka/dijakinjo prijavljam na šolsko malico od",
      required: true,
      type: 'date',
      // * DONE TODO: set default value to the nearest in-date 1.9. XXXX 
    },

    malica_student_can_opt_out: {
      field: "malica_student_can_opt_out",
      label: "Dijak/dijakinja se lahko na malico prijavlja/odjavlja sam-a",
      required: true,
      data: booleanData,
      lookup: booleanLookup,
      type: 'radio'
    },
  },

  books: {
    will_rent_books: {
      field: "will_rent_books",
      label: "Izposoja učbenikov iz učbeniškega sklada",
      required: true,
      data: booleanData,
      lookup: booleanLookup,
      type: 'radio'
    },
  },

  misc: {
    recognized_as_extraordinary: {
      field: "recognized_as_extraordinary",
      label: "Ali si bil/a v OŠ prepoznan/a kot nadarjen/a?",
      required: true,
      data: booleanData,
      lookup: booleanLookup,
      type: 'radio'
    },
    is_intersted_in_choir: {
      field: "is_intersted_in_choir",
      label: "Ali te zanima sodelovanje v mešanem mladinskem pevskem zboru Šolskega centra Velenje?",
      required: true,
      data: booleanData,
      lookup: booleanLookup,
      type: 'radio'
    },
    currently_lives_where: {
      field: "currently_lives_where",
      label: "V času šolanja živiš kje?",
      required: true,
      // TODO: Replace with radio-textfield-combo
      type: 'radio-textfield-combo',
      suggestions: premadeCurrentlyLivingWhere
    },
  },

  commute: {
    is_currently_commuter: {
      field: "is_currently_commuter",
      label: "V času šolanja sem vozač",
      required: true,
      data: booleanData,
      lookup: booleanLookup,
      type: 'radio'
    },
    commute_method: {
      field: "commute_method",
      label: "Način prevoza",
      required: true,
      suggestions: premadeCommuteMethods,
      // TODO: Replace with radio-textfield-combo
      type: 'radio-textfield-combo'
    },
    commute_relation: {
      field: "commute_relation",
      label: "Relacija vožnje",
      required: true,
    },
  },

  odlocba: {
    has_odlocba: {
      field: "has_odlocba",
      label: "Ali imaš veljavno odločbo Zavoda za šolstvo o usmerjanju otroka s posebnimi potrebami ",
      alternativeLabel: 'Ima odločbo?',
      required: true,
      data: odlocbaData,
      lookup: odlocbaLookup,
      type: 'radio'
    },
    odlocba: {
      field: "odlocba",
      label: "Številka odločbe",
      required: true,
    },
  }
};