import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { DeleteOutlineOutlined, RefreshOutlined } from "@material-ui/icons";
import firebase from "firebase/app";
import MaterialTable, { Column } from "material-table";
import { useSnackbar } from "notistack";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { useToggle } from "react-use";
import { useRecoilState } from "recoil";
import { schoolIndexAtom } from "../atoms/form.atom";
import {
  booleanLookup,
  fields,
  ISignUp,
  odlocbaLookup,
} from "../constants/fields";
import { MaterialTableLocals } from "../constants/tableLocalization";
import { ISchool } from "../services/firebase";
import { bulkDelete, exportSignUps } from "../services/firebase.admin";
import { convertTimestamp } from "../utils/convert";

interface Props {}

export interface MappedSignUp extends ISignUp {
  // program_title: string;
  // school_title: string;
}

const CONFIRMATION_STATEMENT = "Da, želim nadaljevati.";

const CURRENT_YEAR = new Date().getFullYear();
const YEAR_START = firebase.firestore.Timestamp.fromDate(
  new Date(`${CURRENT_YEAR}-01-01`)
).toDate();
const YEAR_END = firebase.firestore.Timestamp.fromDate(
  new Date(`${CURRENT_YEAR + 1}-01-01`)
).toDate();

firebase.firestore.Timestamp.fromDate(
  new Date(`${CURRENT_YEAR}-01-01`)
).toDate();
firebase.firestore.Timestamp.fromDate(
  new Date(`${CURRENT_YEAR + 1}-01-01`)
).toDate();

let exportColumns: Column<MappedSignUp>[] = [];
let exportCategories = [
  fields.school,
  fields.student,
  fields.residence,
  fields.parents,
  fields.identification,
  fields.shirtSize,
  fields.certificates,
  fields.malica,
  fields.books,
  fields.misc,
  fields.commute,
  fields.odlocba,
];

exportCategories.forEach((category) => {
  for (let key in category) {
    const entry = category[key];
    let prefix = entry.labelPrefix ? `${entry.labelPrefix} ` : "";

    exportColumns.push({
      field: entry.field,
      title: prefix + (entry.alternativeLabel ?? entry.label),
      hidden: true,
      export: true,
      lookup: entry?.lookup,
    });
  }
});

export default function AdminHome({}: Props): ReactElement {
  const [schools, setSchools] = useState<ISchool[]>([]);
  const [selectedSchool, setSelectedSchool] = useState("all");
  const [selectedProgram, setSelectedProgram] = useState<number | string>(-1);
  const [signUps, setSignUps] = useState<ISignUp[]>([]);
  const [mappedData, setMappedData] = useState<MappedSignUp[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  // For updating the header underlines
  const [schoolIndex, setSchoolIndex] = useRecoilState(schoolIndexAtom);

  useEffect(() => {
    setSchoolIndex({
      index: -1,
      color: "#000",
    });
  }, []);

  // -----------------------------------------------------------------------
  //  For deleting all data
  // -----------------------------------------------------------------------
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [checkDelete, setCheckDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  function closeDeletePrompt() {
    if (isDeleting) return;

    setShowDeleteAll(false);
    setCheckDelete(false);
  }

  function promptDeleteAll() {
    setShowDeleteAll(true);
    setCheckDelete(false);
  }

  async function deleteAllSignups() {
    let promises: Promise<any>[] = [];
    let snapshot = await firebase.firestore().collection("sign_ups").get();
    snapshot.docs.forEach((snapshot) => {
      promises.push(snapshot.ref.delete());
      console.log(snapshot.ref);
    });
    return Promise.all(promises);
  }

  async function deleteAllStorage() {
    const storageRef = await firebase.storage().ref();

    let folders = await (await storageRef.listAll()).prefixes;

    let promises: Promise<any>[] = [];

    for (let folder of folders) {
      let files = await (await folder.listAll()).items;

      for (let file of files) {
        promises.push(file.delete());
      }
    }

    return Promise.all(promises);
  }

  async function deleteAll() {
    setIsDeleting(true);

    try {
      await deleteAllSignups();
      await deleteAllStorage();

      fetchSignUps();
      setShowDeleteAll(false);
      setCheckDelete(false);
      enqueueSnackbar("Uspeh!", { variant: "success" });
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Napaka pri brisanju...", { variant: "error" });
    } finally {
      setIsDeleting(false);
    }
  }

  // -----------------------------------------------------------------------
  //  Fetch all schools, their programs...
  // -----------------------------------------------------------------------
  async function fetchSchools() {
    try {
      let snapshot = await firebase.firestore().collection("schools").get();
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setSchools(data as ISchool[]);
    } catch (e) {
      console.log(e);
    }
  }

  // -----------------------------------------------------------------------
  //  Fetch all signups
  // -----------------------------------------------------------------------
  async function fetchSignUps() {
    try {
      let snapshot = await firebase
        .firestore()
        .collection("sign_ups")
        .where("createDate", ">", YEAR_START)
        .where("createDate", "<", YEAR_END)
        .get();
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setSignUps(data as ISignUp[]);
    } catch (e) {
      console.log(e);
    }
  }

  // -----------------------------------------------------------------------
  //  Perform an initial refresh
  // -----------------------------------------------------------------------
  useEffect(() => {
    refresh();
  }, []);

  // -----------------------------------------------------------------------
  //  When refreshing, we re-fetch all schools and signUps
  // -----------------------------------------------------------------------
  function refresh() {
    fetchSchools();
    fetchSignUps();
  }

  // -----------------------------------------------------------------------
  //  Reset the selected program index whenever the user selects a new school
  // -----------------------------------------------------------------------
  useEffect(() => {
    setSelectedProgram(-1);
  }, [selectedSchool]);

  async function getMapDataAndFetchDownloadUrls() {
    if (!schools || !signUps) return [];

    type MappedSchools = {
      [key: string]: {
        title: string;
        programs: string[];
      };
    };

    let mappedSchools: MappedSchools = {};
    schools.forEach((school) => {
      mappedSchools[school.id] = {
        title: school.short,
        programs: school.programs,
      };
    });

    let newData: ISignUp[] = [];

    for (let entry of signUps) {
      // @ts-expect-error: yeah... i'm doing a boo
      newData.push({
        ...entry,

        program: mappedSchools[entry.school].programs[entry.program],
        school: mappedSchools[entry.school].title,
        createDate: convertTimestamp(entry?.createDate, true),
        malica_from: convertTimestamp(entry?.malica_from),
        date_of_birth: convertTimestamp(entry?.date_of_birth),
      } as ISignUp);
    }

    setMappedData(newData);
  }

  useEffect(() => {
    getMapDataAndFetchDownloadUrls();
  }, [signUps]);

  // -----------------------------------------------------------------------
  //  Filter what data to show based on the user's specifications
  // -----------------------------------------------------------------------
  const filteredData = useMemo(() => {
    let data = mappedData;
    if (selectedSchool != "all")
      data = data.filter((su) => su.school == selectedSchool);
    if (selectedProgram != -1)
      data = data.filter((su) => su.program == selectedProgram);
    return data;
  }, [selectedSchool, selectedProgram, mappedData]);

  // -----------------------------------------------------------------------
  //  Attempt to delete selected signUps
  // -----------------------------------------------------------------------
  async function handleDelete(data: ISignUp[]) {
    if (data.length > 9) {
      enqueueSnackbar("Naenkrat lahko izbrišete največ 10 prijav!", {
        variant: "error",
      });
      return;
    }

    // Map the documents into a list of their IDs
    await bulkDelete((data as ISignUp[]).map((row: ISignUp) => row.id));

    // Refresh the table but only after the deletion
    refresh();
  }

  // -------------------------------------------------------------
  //  Exporting
  // -------------------------------------------------------------
  const [isExporting, toggleExporting] = useToggle(false);
  async function performExport(data: any) {
    try {
      toggleExporting(true);
      await exportSignUps(schools, signUps, data, {
        school: (selectedSchool as string) ?? null,
        program: (selectedProgram as number) ?? null,
      });
    } catch (e) {
      enqueueSnackbar("Izvoz ni uspel!", { variant: "error" });
    }

    toggleExporting(false);
  }

  return (
    <Box>
      <Box my={1} mt={0}>
        <Button variant="contained" color="secondary" onClick={promptDeleteAll}>
          Izbriši prijave.
        </Button>
      </Box>

      <Box display="grid" gridGap="15px" gridTemplateColumns="1fr 1fr">
        <TextField
          variant="filled"
          label="Šola"
          select
          value={selectedSchool}
          onChange={(e) => setSelectedSchool(e.target.value)}
        >
          <MenuItem value={"all"}>VSE</MenuItem>
          {schools.map((sch) => (
            <MenuItem key={sch.id} value={sch.short}>
              {sch.title}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          variant="filled"
          label="Program"
          select
          value={selectedProgram}
          onChange={(e) => setSelectedProgram(e.target.value)}
        >
          <MenuItem value={-1}>VSI</MenuItem>
          {schools
            .find((test) => test.short == selectedSchool)
            ?.programs.map((prog, i) => (
              <MenuItem key={prog} value={prog}>
                {prog}
              </MenuItem>
            ))}
        </TextField>
      </Box>

      <Box height="15px" />

      <MaterialTable
        title=""
        data={filteredData ?? []}
        options={{
          exportAllData: true,
          exportButton: {
            csv: true,
          },
          padding: "dense",
          selection: true,
          pageSize: 30,
          pageSizeOptions: [30, 50, 100, 500],

          exportCsv: (columns, data) => {
            performExport(data);
          },
        }}
        localization={MaterialTableLocals}
        columns={[
          //  The columns are only to be show in the table, not to be exported
          { title: "Šola", field: "school", export: false },
          {
            title: "Program",
            field: "program",
            export: false,
            filtering: true,
          },
          {
            title: "Datum Oddaje",
            field: "createDate",
            defaultSort: "desc",
            export: false,
          },

          { title: "Ime", field: "first_name", export: false },
          { title: "Priimek", field: "last_name", export: false },
          { title: "Spol", field: "gender", export: false },

          {
            title: "Malica",
            field: "malica_is_signing_up",
            lookup: booleanLookup,
            export: false,
          },
          {
            title: "Vozač",
            field: "is_currently_commuter",
            lookup: booleanLookup,
            export: false,
          },
          {
            title: "Odločba",
            field: "has_odlocba",
            lookup: odlocbaLookup,
            export: false,
          },
          {
            title: "Učbenik",
            field: "will_rent_books",
            lookup: booleanLookup,
            export: false,
          },

          //  These are the ones that are exported
          // ...exportColumns
        ]}
        actions={[
          {
            isFreeAction: true,
            tooltip: "Osveži",
            icon: RefreshOutlined,
            onClick: refresh,
          },
          {
            isFreeAction: false,
            tooltip: "Zbriši",
            icon: DeleteOutlineOutlined,
            onClick: (e, data) => handleDelete(data as ISignUp[]),
          },
        ]}
      />

      <Box minHeight={128} mt={2}></Box>

      <Dialog
        onClose={closeDeletePrompt}
        maxWidth="sm"
        fullWidth
        open={showDeleteAll}
      >
        <DialogTitle>Brisanje prijav</DialogTitle>

        <DialogContent>
          <Typography>
            Ali res želite izbrisati vse prijave? Ta akcija je trajna.
          </Typography>

          <FormGroup aria-label="position" row>
            <FormControlLabel
              disabled={isDeleting}
              control={
                <Checkbox
                  checked={checkDelete}
                  onChange={() => setCheckDelete((old) => !old)}
                />
              }
              label={CONFIRMATION_STATEMENT}
              labelPlacement="end"
            />
          </FormGroup>

          {isDeleting && (
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent="center"
              style={{ gap: "1em" }}
              p="1em"
            >
              <CircularProgress /> Brisanje... to lahko traja...
            </Box>
          )}

          <Box mt={0.5}></Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            disabled={!checkDelete || isDeleting}
            onClick={deleteAll}
          >
            Izbriši prijave
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={closeDeletePrompt}
            disabled={isDeleting}
          >
            Preklic
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isExporting}>
        <DialogTitle>Izvoz...</DialogTitle>

        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
