import React, { ReactElement } from 'react'
import Loader from "react-loader-spinner";

export default function Loading(): ReactElement {
  return <div style={{display: 'flex', justifyContent: 'center'}}>
    <Loader
      type="TailSpin"
      color="#00BFFF"
      height={100}
      width={100}
    />
  </div>
}
