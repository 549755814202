import React, { ReactElement } from 'react'
import styled from 'styled-components'

interface Props {
  
}

const Paragraph = styled.p`
  padding-bottom: 15px;
`

const List = styled.ul`
  padding-left: 17px;
`

export default function Instructions({}: Props): ReactElement {
  return (
    <div>
      <div style={{height: 21}}></div>
      <Paragraph>Za potrebe vodenja evidenc ter naročanja na malico in šolskih izkaznic vas prosimo, da izpolnite ta elektronski obrazec. </Paragraph>
      <Paragraph>Preden se lotite izpolnjevanja, priporočamo, da si pripravite vse potrebne podatke. To so: </Paragraph>

      <List>
        <li>telefonske številke dijaka in staršev, </li>
        <li>elektronske naslove,</li>
        <li>EMŠO dijaka,</li>
        <li>davčne številke dijaka in staršev, </li>
        <li>fotografijo oz. scan spričevala zadnjega končanega razreda/letnika,</li>
        <li>fotografijo dijaka za izdelavo ŠCV izkaznice za dijaka (fotografijo poimenujete s svojim imenom in priimkom, fotografija naj bo primerna za izkaznico - fotografija obraza, kot je na osebni izkaznici).</li>
      </List>
      <div style={{height: 21}}></div>
    </div>
  )
}
