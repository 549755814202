import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import { useEffect, useMemo } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {  } from "styled-components";
import { authAtom } from "./atoms/auth.atoms";
import { schoolIndexAtom } from "./atoms/form.atom";
import ColorShiftButton from "./components/ColorShiftButton";
import GlobalStyle from "./components/GlobalStyles";
import Header from "./components/Header";
import { Layout } from "./components/Layout";
import AdminLogin from "./pages/AdminLogin";
import StudentSignUpForm from "./pages/StudentSignUpForm";
import firebase from "firebase/app";
import "firebase/auth";
import AdminHome from "./pages/AdminHome";
import Success from "./pages/Success";

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

function App() {

  const [auth, setAuth] = useRecoilState(authAtom);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if(user) setAuth({
        loggedIn: !!user,
        // @ts-expect-error: it's fine, just a slightly different interface
        user: user
      })
    })

  }, [])

  // const schoolIndex = useRecoilValue(schoolIndexAtom);

  // const theme = useMemo(() => createMuiTheme({
  //     palette: {
  //       primary: {
  //         main: schoolIndex.color,
  //       },
  //     },
  //     props: {
  //       MuiCheckbox: {
  //         color: 'primary'
  //       },
  //       MuiRadio: {
  //         color: 'primary'
  //       },
  //       MuiButton: {
  //         color: 'primary',
  //       },
  //     }
  //   }), [schoolIndex])

  return (
    <>
      <GlobalStyle />
      {/* <ThemeProvider theme={theme} > */}
        <BrowserRouter>
          <CssBaseline />

          <Header />
          <div style={{height: 48}} ></div>

          <Layout bAuth={auth.loggedIn}>
            <Switch>
              <Route exact path="/prijavnica" component={StudentSignUpForm} />
              <Route exact path="/prijavnica/uspeh" component={Success} />
              {!auth.loggedIn && <Route exact path="/admin/login" component={AdminLogin} />}
              {auth.loggedIn && <Route exact path="/admin" component={AdminHome} />}

              {auth.loggedIn && <Redirect to="/admin" />}
              <Redirect to="/prijavnica" />
            </Switch>
          </Layout>
        </BrowserRouter>
      {/* </ThemeProvider> */}
    </>
  );
}

export default App;
