import firebase from "firebase/app";
import "firebase/storage";
import { fields, ISignUp } from "../constants/fields";
import { MappedSignUp } from "../pages/AdminHome";
import { ISchool } from "./firebase";
import papa from 'papaparse';
import { convertTimestamp } from "../utils/convert";

interface IMappedFiles {
  [key: string]: string
}

type ExportFilters = {
  school?: string;
  program?: number
}

// Takes a list of document id's and deletes them one by one.
export async function bulkDelete(docsToDelete: string[]) {

  let snapshot = await firebase.firestore().collection('sign_ups')
    .where(firebase.firestore.FieldPath.documentId(), 'in', docsToDelete).get();
  
  // TODO: DELETE STORAGE FOLDER

  for (const doc of snapshot.docs) {
    await doc.ref.delete();
  }

  return true;
}

let exportCategories = [
  fields.school,
  fields.createDate,
  fields.student,
  fields.residence,
  fields.parents,
  fields.identification,
  fields.shirtSize,
  fields.certificates,
  fields.malica,
  fields.books,
  fields.misc,
  fields.commute,
  fields.odlocba,
]

// ! KEEP THIS FALSE FOR PRODUCTION
const bSkipImageLinkGenerationProcess = false;

// ------------------------------------------------------------------------------------
// Big thanks to Stack Overflow, as always:
// https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side/63744993#63744993
// ------------------------------------------------------------------------------------
export async function exportSignUps(schools: ISchool[], signUps: ISignUp[], mappedData: MappedSignUp[], filters: ExportFilters) {

  // Get a list of all column names and put them in a sequence
  let columnNames: string[] = [];
  for(let cat of exportCategories) {
    for(let entry in cat) {
      let _entry = cat[entry]
      let prefix = (_entry.labelPrefix) ? `${_entry.labelPrefix} ` : '';
      columnNames.push( prefix + (_entry.alternativeLabel ?? _entry.label) )
    }
  }

  let rows: any = [
    // columnNames
  ];

  let filteredSignUps: ISignUp[] = [];
  if(filters?.school) {
    let targetSchool = schools.find(_school => _school.short == filters?.school);

    if(targetSchool && filters?.school != 'all') {
      let schoolId = targetSchool.id;
      
      signUps = signUps.filter(_su => _su.school == schoolId);

      if(filters?.program && typeof filters.program != 'number') {

        let targetProgram = targetSchool?.programs.indexOf(filters.program);

        signUps = signUps.filter(_su => _su.program == targetProgram);
      }
    }
  }

  // Go through every sign up and map its data into the array.
  for(let signUp of signUps) {


    let row: string[] = [];
    // let ref = firebase.storage().ref(signUp.id);
    // let files = (await ref.listAll()).items;

    // Fetch the download URL for every file of the signUp
    let mappedFiles: IMappedFiles = {};
    if(!bSkipImageLinkGenerationProcess) {

      let ref = firebase.storage().ref(signUp.id);
      let files = (await ref.listAll()).items;

      for(let file of files) {
        let url = await file.getDownloadURL();
        mappedFiles[file.name] = encodeURI(url);
      }
    }
   
    for(let cat of exportCategories) {
      for(let entry in cat) {
        let _entry = cat[entry];

        // @ts-expect-error: frick u ts
        let value: any = signUp[_entry.field];

        // The field is present
        if(typeof value != 'undefined' || value != null) {
          if(_entry.field == 'school') {
            let school = schools.find(_school => _school.id == signUp['school'])?.short;
            row.push(school ?? '');
          }
          else if(_entry.field == 'program') {

            console.log('---------------------------');
            console.log('The schools programs: ',schools.find(_school => _school.id == signUp['school'])?.programs);

            let program = schools.find(_school => _school.id == signUp['school'])?.programs[value];

            console.log('The student\'s program: ', program);
            row.push(program ?? '');
          } else if(_entry?.lookup) {
            // @ts-expect-error: frick u ts
            row.push(_entry.lookup[value])
          } else if (_entry?.type == 'date') {

            // @ts-expect-error: frick u ts
            row.push(convertTimestamp(value, _entry?.datePrecise ?? false))
          } else if (_entry?.type == 'file') {
            row.push(mappedFiles[value]);
          } else {
            row.push(`${value}`);
          }
          
          // row.push(signUp[_entry.field]);
        } else {
          row.push('');
        }
      }
    }

    rows.push(row);
  }

  // Use Papa Parser to unparse the JSON data into a CSV string
  const papaConfig: papa.UnparseConfig = {
    
    quotes: true, //or array of booleans
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ";",
    newline: "\r\n",
    skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
    columns: columnNames, //or array of strings
  }

  let csv = papa.unparse([columnNames, ...rows], papaConfig).replaceAll('#', '%23');

  // for(let i = 0; i < rows.length; i++) {
  //   for(let j = 0; j < rows[i].length; j++) {
  //     rows[i][j] = `"${rows[i][j]}"`
  //   }
  // }

  // let csv = rows.map((e: string[]) => e.join(",")).join("\n").replaceAll('#', '%23'); 
  
  // Note: the %EF%BB%BF in the charset assures that we're dealing with Utf-8 with BOM
  // Basically it's for supporting special characters like š, č, ž, ...
  var link = document.createElement("a");
  link.setAttribute("href", `data:text;charset=utf-8,%EF%BB%BF` + (csv));
  link.setAttribute("download", "izvoz.csv");
  document.body.appendChild(link); // Required for FF
  link.click(); 

  // It took me forever to realize that it was the encodeURI() breaking the entire export
  // I fixed the issue by removing the function and doing the "encoding" manually with:
  //    csv.replaceAll('#', '%23');
  //
  // God damn you, encodeURI()
  // link.setAttribute("href", `data:text;charset=utf-8,%EF%BB%BF` + encodeURI(csvContent));
}