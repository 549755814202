import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`

  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;600;700;800&display=swap');

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root {
    --bg: #FFFFFF;
    --text: #000000;

    --shadow-header: 0px 0px 7px 2px rgba(0,0,0,0.2);

    --school-color-1: rgb(169, 208, 70);
    --school-color-2: rgb(0, 148, 217);
    --school-color-3: rgb(238, 91, 160);
    --school-color-4: rgb(255, 202, 5);
  }

  html {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
  }

  body {
    background-color: #f2f2f2;
  }

  button {
    appearance: none;
    background: none;
    outline: none;
    border: none;
    box-shadow: none;
  }

  .footer {
    position: sticky;
    bottom: 0;

    background-color: #f2f2f2e6;
    z-index: 99;

    /* box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.07) */

    /* backdrop-filter: blur(2px) saturate(120%); */
  }
`;
 
export default GlobalStyle;