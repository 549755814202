// This component serves as the all-in-one method for creating inputs for a react-hook-form form.

import { Box, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, TextField, TextFieldProps, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ReactElement } from 'react'
import { Controller, FieldValues, UseFormReturn, ValidationRule } from 'react-hook-form'
import { useToggle } from 'react-use';

export interface FormInputProps {
  form: UseFormReturn<FieldValues>;
  field: string;
  label: string;
  alternativeLabel?: string;
  labelPrefix?: string;
  required?: Boolean;
  data?: IMappedFormData[];
  suggestions?: ISuggestionsFormData;
  type?:  'date' | 'text' |
          'select' | 'radio' |
          'file' | 'autocomplete' |
          'radio-textfield-combo' |
          'radio-misc-input';
  export?: boolean;
  lookup?: object;
  injectInputProps?: TextFieldProps;
  datePrecise?: boolean
}

export interface IMappedFormData {
  label: string;
  value: string | number;
}

export type ISuggestionsFormData = string[];

function RadioError({ show } : { show: boolean }) {
  return show ? <div>
    <Typography variant="caption" color="secondary" >Polje je obvezno!</Typography>
  </div> : null;
}

export default function FormInput(props: FormInputProps): ReactElement {

  const [useCustomRadioEntry, toggleUseCustomRadioEntry] = useToggle(false);


  // Deconstruct the props and add in some default props
  const {
    form,
    field,
    label,
    required = true,
    data = [],
    suggestions = [],
    type = 'text',
    injectInputProps = {},
  } = props;

  const radioOptionalStateToggleField = `${field}_$_toggler`;
  const watchOptionalStateToggle = form.watch(radioOptionalStateToggleField, false); 


  const sharedControllerProps = {
    name: field,
    control: form.control,
    rules: {
      required: !!required as ValidationRule<boolean>
    }
  };

  function toggleUseOptionalRadioField(flag: boolean) {
    form.setValue(radioOptionalStateToggleField, flag);
    
    // Reset the form input when the users opts in for a custom input
    // This is done in order to fix a bug with faulty validation
    flag && form.setValue(field, '');
  }

  const sharedTextFieldProps: TextFieldProps = {
    required: !!required,
    error: !!(form.formState.errors[field]),
    helperText: !!(form.formState.errors[field]) ? 'Polje je obvezno!' : '',
    fullWidth: true, 
    size: 'small',
    margin: 'dense',
    label: label,
    variant: 'filled',
  };

  const bFieldError = !!form.formState.errors[field];


  function getInputBasedOnType() {
    switch(type) {

      case 'text': return (
        <Controller
          {...sharedControllerProps}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              {...sharedTextFieldProps}
              value={value}
              onChange={onChange}
            />
          )}
        />
      );

      case 'radio-misc-input': return (injectInputProps?.disabled ?? true) ?
      
      (<TextField key="disabled-thingy" disabled placeholder="Drugo..." />)
      : (
        <Controller
          {...sharedControllerProps}
          rules={{required: false}}
          key="non-disabled-thingy"
          render={({ field: { onChange, value } }) => (
            <TextField
              // disabled={injectInputProps?.disabled}
              {...sharedTextFieldProps}
              defaultValue={value}
              label={''}
              required={false}
              hiddenLabel={true}
              error={false}
              helperText=""
              aria-label={label}
              variant="standard"
              value={value}
              onChange={onChange}
              {...injectInputProps}
              placeholder="Drugo..." 
            />
          )}
        />
      );

      case 'select': return (
        <Controller
          {...sharedControllerProps}
          render={({ field: { onChange, value } }) => (
            <TextField
              {...sharedTextFieldProps}
              select
              value={value}
              onChange={onChange}
            >
              {data?.map((entry: IMappedFormData) => <MenuItem key={entry.label} value={entry.value} >{entry.label}</MenuItem>)}
            </TextField>
          )}
        />
      );

      case 'date': return (
        <Controller
          {...sharedControllerProps}
          render={({ field: { onChange, value } }) => (
            <TextField
              {...sharedTextFieldProps}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={value}
              onChange={onChange}
            />
          )}
        />
      );

      case 'autocomplete': return (
        <Controller
          {...sharedControllerProps}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              freeSolo
              onChange={(event, item) => {
                onChange(item);
              }}
              value={value}
              options={(props.suggestions ?? []) as string[]}
              getOptionLabel={(option) => option}
              renderOption={(option) => option}
              renderInput={(params => (
                <TextField 
                  {...params}
                  {...sharedTextFieldProps}
                />
              ))}
            />
          )}
        />
      );

      // This part is a little hacky
      // Since the component can't track state between steps, we use the form has a vessel for state.
      // Pretty big brain idea, I know! 
      case 'radio-textfield-combo': return (
        <>
          <Box height={21}> </Box>
            <FormControl component="fieldset">
            {/* <FormLabel component="legend" aria-label={label}  >{label}</FormLabel> */}
            <Typography variant="body1" >{label}</Typography>

            <Controller
              rules={{ required: true }}
              control={form.control}
              name={field}
              render={({ field: fieldProps }) => (<>
                <RadioGroup
                  {...fieldProps}
                >
                  {suggestions?.map((entry) => <FormControlLabel value={entry} key={entry} control={<Radio onClick={() => toggleUseOptionalRadioField(false)}  />} label={entry} onChange={fieldProps.onChange} checked={!useCustomRadioEntry && entry == fieldProps.value} />)}
                  {/* <FormControlLabel  control={<Radio />} label={ <FormInput {...props} type="radio-misc-input" injectInputProps={{disabled: !useCustomRadioEntry }} />} onChange={fieldProps.onChange} checked={useCustomRadioEntry} onClick={() => toggleUseCustomRadioEntry(true)} /> */}
                
     

                  <FormControlLabel
                    control={<Radio  onClick={() => toggleUseOptionalRadioField(true)}  checked={watchOptionalStateToggle ?? false} />}
                    label={<FormInput {...props} type="radio-misc-input" injectInputProps={{
                      disabled: (!watchOptionalStateToggle) ?? true
                    }} />}
                  />
                  <Box height="15px" />
                  

                </RadioGroup>

                
                {/* <FormControlLabel  value={fieldProps.value} key={fieldProps.value} control={<Radio />} label={ <FormInput {...props} type="radio-misc-input" />} onChange={fieldProps.onChange} checked={!suggestions.includes(fieldProps.value)} /> */}

                {/* Current value: {form.getValues()[props.field]} */}

              </>)}
            />

            <RadioError show={bFieldError} />
          </FormControl>
        </>
      );

      case 'radio': return (
        <>
          <Box height={21}> </Box>
            <FormControl component="fieldset">
            <Typography variant="body1" >{label}</Typography>

            <Controller
              rules={{ required: true }}
              control={form.control}
              name={field}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                >
                {
                  data?.map((entry) => <FormControlLabel  value={entry.value} key={entry.label} control={<Radio />} label={entry.label} onChange={field.onChange} checked={entry.value == field.value} />)
                }
                </RadioGroup>
              )}
            />

            <RadioError show={bFieldError} />
          </FormControl>
        </>
      );

      // case 'radio-textfield-combo-toggler': return (
      //   <>
      //       <Controller
      //         rules={{ required: true }}
      //         control={form.control}
      //         name={field}
      //         render={({ field }) => (
      //           <RadioGroup
      //             {...field}
      //           >
      //           {
      //             data?.map((entry) => <FormControlLabel  value={entry.value} key={entry.label} control={<Radio />} label={entry.label} onChange={field.onChange} checked={entry.value == field.value} />)
      //           }
      //           </RadioGroup>
      //         )}
      //       />
      //   </>
      // );

      case 'file': return (<>
        <Typography variant="body1" >{label}</Typography>
        {/* <Controller
          rules={{ required: true }}
          control={form.control}
          name={field}
          render={({ field: { onChange, value } }) => (
            // <input type="file" onChange={onChange} defaultValue={value} />
            
          )}
        /> */}
        <input type="file" {...form.register(field, { required: true })} />
        <RadioError show={bFieldError} />
        <Box height="1em" ></Box>
      </>);
    }
  }

  return <div key={field} >
    {getInputBasedOnType()}
  </div>
}
