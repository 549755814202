import { Button } from "@material-ui/core";
import React, { ReactElement } from "react";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../atoms/auth.atoms";
import { schoolIndexAtom } from "../../atoms/form.atom";
import { HeaderLink, HeaderNav, HeaderRainbow, HeaderRoot, HeaderTitle } from "./styles";
import firebase from "firebase/app";
import 'firebase/auth'
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

interface Props {}

export default function Header({}: Props): ReactElement {

  const schoolIndex = useRecoilValue(schoolIndexAtom);
  const user = useRecoilValue(authAtom);
  const history = useHistory();

  async function logout() {
    await firebase.auth().signOut();

    // Reload the page to properly log out
    window.location.reload();
  }

  function gotoDashboard() {
    history.push('admin');
  }

  return <HeaderRoot>
    <HeaderTitle>
      <Link to="/prijavnica" >
      eVpis
      </Link>
    </HeaderTitle>
    
    {user.loggedIn && <HeaderNav>
      {/* <HeaderLink to="/" >Domov</HeaderLink> */}

      <Button color="primary" onClick={() => gotoDashboard()} >Pojdi v konzolo</Button>
      <Button color="secondary" onClick={() => logout()} >Odjava</Button>
    </HeaderNav>}

      <HeaderRainbow>
        {[0, 1, 2, 3].map(index => <div  className={`${index == schoolIndex.index ? 'activeSchoolAccent' : ''}`} ></div>)}
      </HeaderRainbow>
  </HeaderRoot>;
}
