import firebase from "firebase/app";

export function convertTimestamp(timestamp: firebase.firestore.Timestamp, exact: boolean = false) {
  if(!timestamp) return null;

  let date = timestamp.toDate();

  if(exact)
    return `${date.toISOString().split('T')[0]} ${date.toLocaleTimeString()}`;

  return date.toISOString().split('T')[0];
}

