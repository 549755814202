import { Link } from "react-router-dom";
import styled from "styled-components";

export const HeaderRoot = styled.header`
  position: sticky;
  position: relative;
  top: 0;
  right: 0;
  left: 0;

  display: flex;
  justify-content: space-between;

  background-color: var(--bg);
  box-shadow: var(--shadow-header);

  z-index: 999;
`;

export const HeaderTitle = styled.span`
  font-size: 1.2rem;
  padding: .72rem 1.5rem;

  & a {
    text-decoration: none;
    color: black;
  }
`;

export const HeaderNav = styled.nav`
  display: flex;
`;
 
export const HeaderLink = styled(Link)`
  text-align: center;
`

export const HeaderRainbow = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  
  display: flex;

  & > div {
    flex: 1;
    border-top: 3px solid;

    transition: flex .6s ease;
  }

  & > div.activeSchoolAccent {
    flex: 15;
  }

  div:nth-child(3) { border-color: var(--school-color-1); }
  div:nth-child(1) { border-color: var(--school-color-2); }
  div:nth-child(4) { border-color: var(--school-color-3); }
  div:nth-child(2) { border-color: var(--school-color-4); }
`