import { ReactElement, useEffect, useMemo, useState } from 'react'
import Instructions from '../components/Instructions';
import { Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel,  MenuItem, Radio, RadioGroup, Select, Typography } from '@material-ui/core';
import { ISchool, useFirebase } from '../services/firebase';
import { useCounter, useToggle } from 'react-use';
import { useForm } from 'react-hook-form';
import { defaultMalicaDate, fields, ISignUp } from '../constants/fields';
import { useRecoilState } from 'recoil';
import { schoolIndexAtom } from '../atoms/form.atom';
import Loading from '../components/Loading';
import FormInput, { FormInputProps } from '../components/Forms/FormInput';
import firebase from 'firebase/app'
import { Prompt, useHistory } from 'react-router';

interface Props {
  
}

function isEmpty(obj: object) {
  return Object.keys(obj).length === 0;
}

const steps = [
  'Začetek',
  'Dijak/inja',
  'Bivališče',
  'Podatki o starših/skrbnikih',
  'ŠCV Izkaznica',
  'ŠCV Majica',
  'Spričevalo',
  'Prijava na šolsko malico',
  'Učbeniški sklad',
  'Ostali Podatki',
  'Vozač',
  'Odločba',
  'Zaključek'
];

export default function StudentSignUpForm({}: Props): ReactElement {

  const api = useFirebase(true);
  const form = useForm({
    mode: 'onChange'
  });
  const history = useHistory();

  const [step, { inc: incStep, dec: decStep }] = useCounter(0);
  const ready = useMemo(() => isEmpty(api.data), [api.data]);

  const [school, setSchool] = useState<string>('');
  const [program, setProgram] = useState(-1);

  // For updating the header underlines
  const [schoolIndex, setSchoolIndex] = useRecoilState(schoolIndexAtom);
  const watchForm = form.watch();

  const [isUploading, toggleUploading] = useToggle(false);
  const [isError, toggleIsError] = useToggle(false);

  useEffect(() => {
    // We get a bunch of errors by setting a default value with useForm's built-in way of setting default values, so we
    // get around this by externaly setting the value
    form.setValue('malica_from', defaultMalicaDate);
  }, [])

  // Whenever the user selects a school we reset the program so they must pick a new program that only belongs to the newly selected school
  function handleSetSchool(id: string) {
    if(school !== id) {
      setSchool(id);
      setProgram(-1);
    }
  }

  // Update the school index whenever the user picks a new school
  useEffect(() => {
    if(school != '') {
      setSchoolIndex({
        index: api.data.findIndex(entry => entry.id == school),
        color: api.data.find(entry => entry.id == school)?.color ?? 'black'
      })
    }
  }, [school])

  function areSchoolAndProgramValid() {
    return (program != -1 && school != '');
  }

  // Validate specific fields - used for the stepper to prevent increasing steps when there are invalid fields
  async function validate() {

    // Checks an entire category of feilds
    const getRequiredFieldsInCategory = (fieldCategory: any) => {
      let requiredFields = [];
      let fieldsInCategory = Object.keys(fieldCategory);
      for(let entryInCategory of fieldsInCategory) {
        if(fieldCategory[entryInCategory].required == true) {
          requiredFields.push(fieldCategory[entryInCategory].field);
        }
      }
      return requiredFields;
    } 
    
    // Since some optional fields are... optional, we gotta do some custom validations
    switch(step) {
      case 0: return areSchoolAndProgramValid();
      case 1: return await form.trigger(getRequiredFieldsInCategory(fields.student));
      case 2: return await form.trigger(getRequiredFieldsInCategory(fields.residence));
      case 3: return await form.trigger(getRequiredFieldsInCategory(fields.parents));

      case 4: return await form.trigger(getRequiredFieldsInCategory(fields.identification));
      case 5: return await form.trigger(getRequiredFieldsInCategory(fields.shirtSize));
      case 6: return await form.trigger(getRequiredFieldsInCategory(fields.certificates));
      case 7: return await (watchForm['malica_is_signing_up'] == 1 ?
        form.trigger(getRequiredFieldsInCategory(fields.malica)) : 
        form.trigger('malica_is_signing_up'));
      case 8: return await form.trigger(getRequiredFieldsInCategory(fields.books));
      case 9: return await form.trigger(getRequiredFieldsInCategory(fields.misc));
      case 10: return await (watchForm['is_currently_commuter'] == 1 ?
        form.trigger(getRequiredFieldsInCategory(fields.commute)) : 
        form.trigger('is_currently_commuter'));
      case 11: return await (watchForm['has_odlocba'] == 1 ?
        form.trigger(getRequiredFieldsInCategory(fields.odlocba)) : 
        form.trigger('has_odlocba'));

      default: return true; 
    }
  }

  async function handleSubmit() {

    // Show the user an unclosable dialog telling them that the upload is happening
    toggleUploading(true);

    let finalData: any = {};
    let formValues = form.getValues();

    // First extract all required fields
    [
      'first_name',
      'last_name',
      'gender',
      'date_of_birth',
      'city_of_birth',
      'country_of_birth',
      'emso',
      'tax_number',

      
      'permanent_street',
      'permanent_post_number',
      'permanent_city',
    
      'parent1_first_name',
      'parent1_last_name',
      'parent1_street',
      'parent1_post_number',
      'parent1_city',
      'parent1_tax_number',

      'malica_is_signing_up',
      'will_rent_books',
  
      'recognized_as_extraordinary',
      'is_intersted_in_choir',
      'currently_lives_where',
    
      'is_currently_commuter',
      'has_odlocba',
    ].forEach(prop => finalData[prop] = formValues[prop]);

    // Then extract some completly optional fields
    [
      'phone',
      'email',

      'permanent_house_number',

      'temporary_street',
      'temporary_house_number',
      'temporary_post_number',
      'temporary_city',

      'parent1_phone',
      'parent1_email',
      'parent1_house_number',

      'shirtSize',

      'parent2_first_name',
      'parent2_last_name',
      'parent2_street',
      'parent2_post_number',
      'parent2_city',
      'parent2_tax_number',
      'parent2_phone',
      'parent2_email',
      'parent2_house_number',
    ].forEach(prop => {
      if(!!formValues[prop]) {
        finalData[prop] = formValues[prop];
      }
    });


    // Exract conditionals
    if(formValues['malica_is_signing_up'] == "1") {
      [
        'malica_from',
        'malica_student_can_opt_out',
      ].forEach(prop => {
        finalData[prop] = formValues[prop];
      });
    } 

    if(formValues['is_currently_commuter'] == "1") {
      [
        'commute_method',
        'commute_relation',
      ].forEach(prop => {
        finalData[prop] = formValues[prop];
      });
    } 

    if(formValues['has_odlocba'] == "1") {
      [
        'odlocba',
      ].forEach(prop => {
        finalData[prop] = formValues[prop];
      });
    }

    
    // Convert some strings into numbers
    [
      'has_odlocba',
    ].forEach(prop => {
      if(finalData[prop]) {
        finalData[prop] = parseInt(formValues[prop])
      }
    });

    // Convert some strings into booleans
    [
      'malica_is_signing_up',
      'malica_student_can_opt_out',
      'will_rent_books',
      'recognized_as_extraordinary',
      'is_intersted_in_choir',
      'is_currently_commuter',
    ].forEach(prop => {
      if(finalData[prop]) {
        finalData[prop] = (finalData[prop] == '1' ? true : false)
      }
    });

    // Convert some strings into dates
    [
      'date_of_birth',
      'malica_from',
    ].forEach(prop => {
      if(finalData[prop]) {
        finalData[prop] = firebase.firestore.Timestamp.fromDate(new Date(finalData[prop])).toDate();
      }
    });

    // Add the creation date of the sign_up
    finalData.createDate = firebase.firestore.Timestamp.now();

    // Add some additional data that wasn't present in the form 
    finalData.school = school;
    finalData.program = program;


    // Attach the file names as fields - we use these to identifiy the uploaded images
    finalData.identificationPhoto = formValues['identificationPhoto'][0].name;
    finalData.lastYearCertificatePhoto = formValues['lastYearCertificatePhoto'][0].name;

    if(formValues['examCertificatePhoto'][0]?.name)
    finalData.examCertificatePhoto = formValues['examCertificatePhoto'][0]?.name;

    try {

      // For the last part, we first create the form
      const doc = await firebase.firestore().collection('sign_ups').add(finalData);

      const fileFields = [
        formValues['identificationPhoto'],
        formValues['lastYearCertificatePhoto'],
        formValues['examCertificatePhoto'] ?? null,
      ];
      let ref = firebase.storage().ref(doc.id);

      // If the form has been successfuly created, we then upload the supplied images and wait for them to all be created until 
      // telling the user the upload has been successfull.
      for(let i = 0; i < fileFields.length; i++) {
        // Skip a file if there ain't a file 
        if((fileFields[i] as FileList).length == 0) continue;
        let file = fileFields[i][0];

        await ref.child(file.name).put(file);
      }

      // The upload was a success! Redirect the user
      history.push('/prijavnica/uspeh')
    } catch (e) {

      // Tell the user there was a problem uploading. This shouldn't ever really happen unless Google goes out of business or 
      // the user tempers with the javascript. This could never ever possibly be my fault due to poor manual form validation. Never.
      toggleUploading(false);
      toggleIsError(true);
    }
  }

  // Validate before incrementing by a step
  async function handleGotoNextStep() {
    if(await validate()) 
      incStep();
  }

  function handleGoBack() {
    decStep();
  }


  // To prevent the repeating of the 'form' prop we use this wrapper which inserts the prop 
  //
  // The Wrapper is a memo otherwise ALL inputs would rerender whenever the onChange event is fired. Big not good. 
  // Is this the best solution? No. Is it a good solution? Nope. But does it work? Hell yes, it does!
  const FormInputWrapper = useMemo(() => function ({formInputProps} : { formInputProps: Omit<FormInputProps, 'form'>}) {
    return <FormInput {...formInputProps} form={form}  />
  }, [])

  function getStepContent() {
    switch(step) {
      case 0: return <>
        <Instructions></Instructions>

        <FormControl component="fieldset">
          <RadioGroup aria-label="gender" name="gender1" value={school} onChange={(e) => handleSetSchool(e.target.value as string)}>
            {
              api.data.map((entry: ISchool) => <FormControlLabel  value={entry.id} key={entry.id} control={<Radio color="primary" />} label={entry.title} />)
            }
          </RadioGroup>
        </FormControl>

        <Box maxWidth={500}>
          { <FormControl fullWidth variant="filled" >
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              Izberi Program
            </InputLabel>
            <Select
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              value={program}
              onChange={(e) => setProgram(parseInt(e.target.value as string))}
              variant="filled" disabled={!school}
              defaultValue={-1}
              displayEmpty
            >
              {api.data?.find((entrySchool: ISchool) => entrySchool.id == school)?.programs.map((entry, i) => <MenuItem value={i} key={entry} >{entry}</MenuItem>)}
            </Select>
          </FormControl>}
        </Box>
      </>

      case 1: return <>
        {/* <FormInput {...{...fields.student.firstName, form}}    /> */}
        <FormInputWrapper formInputProps={fields.student.first_name}  />
        <FormInputWrapper formInputProps={fields.student.last_name}  />
        <FormInputWrapper formInputProps={fields.student.gender}  />

        <FormInputWrapper formInputProps={fields.student.date_of_birth}  />
        <FormInputWrapper formInputProps={fields.student.city_of_birth}  />
        <FormInputWrapper formInputProps={fields.student.country_of_birth}  />
        <FormInputWrapper formInputProps={fields.student.emso}  />
        <FormInputWrapper formInputProps={fields.student.tax_number}  />
        <FormInputWrapper formInputProps={fields.student.phone}  />
        <FormInputWrapper formInputProps={fields.student.email}  />
      </>

      case 2: return <>
        <Typography variant="h6">Stalno Prebivališče</Typography>
        <FormInputWrapper formInputProps={fields.residence.permanent_street}  />
        <FormInputWrapper formInputProps={fields.residence.permanent_house_number}  />
        <FormInputWrapper formInputProps={fields.residence.permanent_post_number}  />
        <FormInputWrapper formInputProps={fields.residence.permanent_city}  />

        <Box height="1em" ></Box>
        <Typography variant="h6">Začasno Prebivališče (neobvezno)</Typography>
        <FormInputWrapper formInputProps={fields.residence.temporary_street}  />
        <FormInputWrapper formInputProps={fields.residence.temporary_house_number}  />
        <FormInputWrapper formInputProps={fields.residence.temporary_post_number}  />
        <FormInputWrapper formInputProps={fields.residence.temporary_city}  />
      </>

      case 3: return <>
        <FormInputWrapper formInputProps={fields.parents.parent1_first_name}  />
        <FormInputWrapper formInputProps={fields.parents.parent1_last_name}  />
        <FormInputWrapper formInputProps={fields.parents.parent1_street}  />
        <FormInputWrapper formInputProps={fields.parents.parent1_house_number}  />
        <FormInputWrapper formInputProps={fields.parents.parent1_post_number}  />
        <FormInputWrapper formInputProps={fields.parents.parent1_city}  />
        <FormInputWrapper formInputProps={fields.parents.parent1_tax_number}  />
        <FormInputWrapper formInputProps={fields.parents.parent1_phone}  />
        <FormInputWrapper formInputProps={fields.parents.parent1_email}  />

        <Box height="1em" ></Box>
        <Typography variant="h6">Podatki o starših/skrbnikih #2 (neobvezno)</Typography>
        <FormInputWrapper formInputProps={fields.parents.parent2_first_name}  />
        <FormInputWrapper formInputProps={fields.parents.parent2_last_name}  />
        <FormInputWrapper formInputProps={fields.parents.parent2_street}  />
        <FormInputWrapper formInputProps={fields.parents.parent2_house_number}  />
        <FormInputWrapper formInputProps={fields.parents.parent2_post_number}  />
        <FormInputWrapper formInputProps={fields.parents.parent2_city}  />
        <FormInputWrapper formInputProps={fields.parents.parent2_tax_number}  />
        <FormInputWrapper formInputProps={fields.parents.parent2_phone}  />
        <FormInputWrapper formInputProps={fields.parents.parent2_email}  />
      </>

      case 4: return <>
        <Typography>Izkaznica  je v skladu s Pravilnikom o ŠCV izkaznici pogoj za vstop v šolske prostore, omogoča izposojo knjig v knjižnici, prevzem malice v šolski jedilnici ter uveljavljanje različnih popustov.</Typography>
        <Box height="1em" ></Box>
        <FormInputWrapper formInputProps={fields.identification.identificationPhoto}  />
      </>

      case 5: return <>
        <Typography>Na šoli boste imeli možnost naročiti šolsko majico, s šolskim logotipom. Prosimo, če nam lahko zgolj informativno sporočite konfekcijsko številko: </Typography>
        <Box height="1em" ></Box>

        <FormInputWrapper formInputProps={fields.shirtSize.shirtSize}  />
      </>

      case 6: return <>
        <Typography>Priloži fotografijo ali scan spričevala zadnjega zaključenega razreda ali letnika in zaključnega izpita, če si ga opravljal/a:</Typography>
        <Box height="1em" ></Box>
        
        <FormInputWrapper formInputProps={fields.certificates.lastYearCertificatePhoto}  />
        <FormInputWrapper formInputProps={fields.certificates.examCertificatePhoto}  />
      </>
      
      case 7: return <>
        <Typography>Dijaki imajo v okviru šolske malice na voljo izbirati med šestimi različnimi meniji (dnevni mesni meni, dnevni vegetarijanski meni, hamburger, pica, solatni krožnik, sendvič). Polna cena malice znaša 2,73 €, z oddajo vloge na Centru za socialno delo pa lahko pridobite subvencijo šolske malice. Podrobne informacije o šolski prehrani najdete na spletni strani šole. </Typography>
        <Box height="1em" ></Box>
        
        <FormInputWrapper formInputProps={fields.malica.malica_is_signing_up}  />
        
        <Collapse  in={form.getValues()['malica_is_signing_up'] == 1} >
          <FormInputWrapper formInputProps={fields.malica.malica_from}  />
          <FormInputWrapper formInputProps={fields.malica.malica_student_can_opt_out}  />
        </Collapse>
      </>

      case 8: return <>
        <Typography>Večino učbenikov si lahko izposodite v našem učbeniškem skladu. Cena obrabnine učbenikov znaša največ tretjino vrednosti novega učbeniškega kompleta. Podrobne informacije o seznamu učbenikov in delovnih zvezkov najdete na spletni strani šole. </Typography>
        <Box height="1em" ></Box>
        <FormInputWrapper formInputProps={fields.books.will_rent_books}  />
      </>

      case 9: return <>
        <FormInputWrapper formInputProps={fields.misc.recognized_as_extraordinary}  />
        <FormInputWrapper formInputProps={fields.misc.is_intersted_in_choir}  />
        <FormInputWrapper formInputProps={fields.misc.currently_lives_where}  />
      </>

      case 10: return <>
        <FormInputWrapper formInputProps={fields.commute.is_currently_commuter}  />

        <Collapse  in={form.getValues()['is_currently_commuter'] == 1} >
          <FormInputWrapper formInputProps={fields.commute.commute_method}  />
          <FormInputWrapper formInputProps={fields.commute.commute_relation}  />
        </Collapse>
      </>

      case 11: return <>
        <FormInputWrapper formInputProps={fields.odlocba.has_odlocba}  />

        <Collapse  in={form.getValues()['has_odlocba'] == 1} >
          <FormInputWrapper formInputProps={fields.odlocba.odlocba}  />
        </Collapse>
      </>
      
      case 12: return <>
        <Typography>Če želite preverite podatke se pomaknite z gumbom "NAZAJ" nazaj po prijavnici.</Typography>
        <Typography>V kolikor so podatki ustrezni - kliknite na gumb "ODDAJ".</Typography>
      </>
    }
  }
  
  return (
    <div>

      <Prompt
        when={!isUploading}
        message='Imate neshranjene spremembe. Ali ste prepričani, da želite zapustiti to stran?'
      />

      {ready ?
      <Loading></Loading>
      :
      <>
        <Box display="flex" justifyContent="space-between" gridGap="15px" alignItems="center" >
          <h1>{steps[step]}</h1>
          <h4 style={{color: 'rgba(0,0,0,0.8)'}} >Postopek {step+1} od {steps.length}</h4>
        </Box>

        <form>
          <Box minHeight="150px" key={steps[step]}>
            {getStepContent()}
          </Box>

          <Box className="footer" display="flex" justifyContent="space-between" gridGap="15px" alignItems="center" >
            <div style={{marginTop: 10, marginBottom: 15, display: 'flex', gap: 5}}>
              {(step > 0) && <Button disableElevation variant="text"  onClick={() => handleGoBack()} >Nazaj</Button>}
              {(step == steps.length - 1) 
                ? <Button disableElevation variant="contained" onClick={handleSubmit} >Oddaj</Button>
                : <Button disableElevation variant="contained" color="primary" disabled={step == 0 ? !areSchoolAndProgramValid() : false} onClick={() => handleGotoNextStep()} >Naprej</Button> }
              {/* // ! REMOVE FOR PRODUCTION */}
              {/* <Button onClick={() => incStep()} >Force next (REMOVE THIS)</Button> */}
            </div>

            <h4 style={{color: 'rgba(0,0,0,0.8)'}} >Postopek {step+1} od {steps.length}</h4>
          </Box>
        </form>
      </>
      }

      <Dialog maxWidth="xs" fullWidth open={isUploading} >
        <DialogTitle >
          <Box display="flex" justifyContent="center" >
            Prosimo počakajte
          </Box>

        </DialogTitle>
        <DialogContent>
          <Box  display="flex" justifyContent="center"  flexDirection="column" alignItems="center" >   
            <Typography>Ne zapreti brskalnika!</Typography>
            
            <Box marginTop="25px" color={schoolIndex?.color}  > 
              <CircularProgress  color="inherit" />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog maxWidth="xs" fullWidth open={isError} >
        <DialogTitle >
          <Box display="flex" >
            Nekaj je šlo narobe ...
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box  >   
            <Typography>
              Prišlo je do napake pri nalaganju vaših podatkov in <strong>vaša prijava ni bila oddana</strong>.
            </Typography> <br />

            <Typography>
              Poskusite prijavo oddati ponovno. V kolikor se bo napaka ponovno zgodila nas kontaktirajte na <strong>urska.koletnik@scv.si</strong>
            </Typography> <br />

            <Typography>
              Hvala za razumevanje.
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => toggleIsError(false)} >Zapri</Button>
        </DialogActions>
      </Dialog>

      <Box height={100} />
    </div>
  )
}
