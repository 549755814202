import { Localization } from "material-table";

export const MaterialTableLocals: Localization = {
  body: {
    emptyDataSourceMessage: 'Prazno'
  },
  header: {
    actions: '',
    
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} od {count}',
    labelRowsSelect: 'vrstic',
    labelRowsPerPage: 'Vrstic na strani',
    firstAriaLabel: 'Prva Stran',
    firstTooltip: 'Prva Stran',
    previousAriaLabel: 'Prejšnja Stran',
    previousTooltip: 'Prejšnja Stran',
    nextAriaLabel: 'Naslednja Stran',
    nextTooltip: 'Naslednja Stran',
    lastAriaLabel: 'Zadnja Stran',
    lastTooltip: 'Zadnja Stran',
  },
  toolbar: {
    searchPlaceholder: 'Išči',
    searchTooltip: 'Išči',
    nRowsSelected: rowCount => `Izbranih vrstic: ${rowCount} (največ 10)`,
    exportTitle: 'Izvozi',
    exportAriaLabel: 'Izvozi',
    exportCSVName: 'Izvozi CSV',
    exportPDFName: 'Izvozi PDF'
  },

}