import React, { ReactElement } from 'react'

interface Props {
  
}

export default function Success({}: Props): ReactElement {
  return (
    <div>
      <h3>Prijavnica uspešno oddana!</h3>
    </div>
  )
}
