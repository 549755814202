import { Box, Button, TextField, TextFieldProps, Typography } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { Control, Controller, FieldValues, useForm, UseFormReturn } from 'react-hook-form'
import { useFirebase } from '../services/firebase'

interface Props {
  
}

interface FieldProps {
  form: UseFormReturn<FieldValues>
  name: string
  label: string
  type: 'email' | 'password'
}

const sharedInputProps: TextFieldProps = {
  variant: 'filled'
}

function Field({form, name, label, type}: FieldProps) {
  return <Controller
    control={form.control}
    name={name}
    defaultValue=""
    rules={{
      required: true
    }}
    render={({ field: { onChange, value } }) => (
      <TextField
        type={type}
        label={label}
        {...sharedInputProps}
        value={value}
        onChange={onChange}
      />
    )}
  />
}

export default function AdminLogin({}: Props): ReactElement {

  const form = useForm();
  const api = useFirebase(false);

  function onSubmit(data: any) {
    api.AdminLogin(data.email, data.password);
  }

  return (
    <form  onSubmit={form.handleSubmit(onSubmit)} >
      <Box display="flex" justifyContent="center"  >
        <Box display="flex" flexDirection="column" gridGap="10px" maxWidth="400px" minWidth="300px" >
          <Typography>eVpis admin prijava</Typography>

          <Field form={form} name="email" label="E-Naslov" type="email" />
          <Field form={form} name="password" label="Geslo" type="password" />

          <Box height="15px"></Box>
          <Button variant="contained" type="submit" >Prijava</Button>

          <Typography color="secondary" >
            {(form.formState.errors['email'] || form.formState.errors['password']) && 'Nepravilen vnos!'}
          </Typography>
        </Box>
      </Box>
    </form>
  )
}
