import firebase from 'firebase';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import App from './App';

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyDE4C5wqzYRNLfKkNiyh7pbhMLNUL0v0zA",
  authDomain: "scv-evpis.firebaseapp.com",
  projectId: "scv-evpis",
  storageBucket: "scv-evpis.appspot.com",
  messagingSenderId: "719795711230",
  appId: "1:719795711230:web:66ead8f5a82322360c09af"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);
